<template>
  <section>
    <!-- LANDING -->
    <div class="home pt-5">
      <div class="container">
        <h1 class="content-header">Welcome to this section on career resilience</h1>
        <!-- CONTENT -->
        <div class="content-wrapper">
          <div class="row">
            <div class="col-sm order-sm-1">
              <button class="btn" @click="openPopup = true">
                <img
                  src="@/assets/img/_content/career-landing-lightbox.png"
                  class="img-fluid"
                  alt="Bio"
                >
              </button>
            </div>
            <div class="col-sm">
              <div class="row align-items-center">
                <!-- <div class="col-sm">
                  <img
                    src="@/assets/img/_content/personal-learn-1.jpg"
                    class="img-thumb img-fluid"
                    alt=""
                  >
                </div> -->
                <div class="col-sm">
                  <h2 class="pt-4">Meet Tracey and Becky</h2>
                  <p>Tracey Lloyd (PhD) is the Director of Career Services and Co-operative Education at Centennial College. Tracey has over 20 years of experience in career education and employment services, working in the nonprofit and postsecondary sectors. She currently oversees career education programming and employer relations, and also co-chairs the College’s Graduate Employment Committee. Prior to joining Centennial, Tracey worked in the community as Director of Employment Programs at Tropicana Community Services - a nonprofit organization that provides a range of services for youth, newcomers and members of the Black and Caribbean communities. Tracey believes that career education is a social imperative. Intentional career education helps people realize possibilities for themselves in the world of work and has the potential to transform lives.</p>
                  <p>Becky Robinson (M.Couns) is a career counsellor who has worked in the Career Services and Cooperative Education department at Centennial College since 2014. She has been working in post-secondary career services for well over a decade, supporting students in navigating their career journeys with confidence and curiosity. Since 2017, Becky has co-led Centennial’s Career Resilience working group, which aims to bring awareness of and further build students’ resilience as they face career uncertainty and a challenging job search.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROUTING NAV -->
      <div
        class="routing-nav"
        id="route02"
      >
        <h2>How would you like to support your students today?</h2>
        <div>
          <router-link to="career/learn" class="routing-nav__btn routing-nav__btn--large">
            <strong>Learn</strong> about career resilience
          </router-link>
          <router-link to="career/discuss" class="routing-nav__btn routing-nav__btn--large">
            <strong>Discuss</strong> resilience with students
          </router-link>
          <router-link to="career/share" class="routing-nav__btn routing-nav__btn--large">
            <strong>Share</strong> resources with students
          </router-link>
          <router-link to="career/design" class="routing-nav__btn routing-nav__btn--large">
            <strong>Design</strong> curriculum that promotes resilience
          </router-link>
          <router-link to="career/review" class="routing-nav__btn routing-nav__btn--large">
            <strong>Review</strong> research by resilience experts
          </router-link>
        </div>
      </div>

      <!-- MODAL -->
      <modal-popup v-if="openPopup" @close="openPopup = false">
        <div class="video-player">
          <!-- video element -->
          <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/BymzXvRTfJw?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
          <!-- <vue-plyr>
            <video
              controls
              crossorigin
              playsinline
            >
              <source
                size="720"
                src="https://staging.gevc.ca/centennial/tic/videos/personal/learn/1.mp4"
                type="video/mp4"
              />
              <track
                label="English"
                kind="subtitles"
                srclang="en"
                src="https://staging.gevc.ca/centennial/tic/cc/career/landing/01.en.vtt"
                default
              >
            </video>
          </vue-plyr> -->
          <!-- <div class="ratio ratio-16x9">
            <iframe src="https://www.youtube.com/embed/NpEaa2P7qZI" title="YouTube video" allowfullscreen></iframe>
          </div> -->
          <div class="accordion">
            <accordion
              headerText="Transcript"
              accordionID="transcript01"
            >
                <p><strong>Tracey Lloyd</strong>: Welcome to the Career Resilience Module. My name is Tracey Lloyd, and I'm the director of Career Services and Cooperative Education at Centennial College.  </p>
                <p><strong>Becky Robinson</strong>: And I am Becky Robinson. I am the career counsellor with Career Services and Cooperative Education, also at Centennial College. We are delighted to introduce you to this section of the Thriving In the Classroom toolkit, which focuses on career resilience. As this toolkit is being launched, we are possibly emerging from the global pandemic, which for all of us has been an exercise in resilience. But for many of our students, it has been even more challenging as they have had to adjust to changes in learning environments and a disrupted and very uncertain employment landscape.  </p>
                <p><strong>Tracey</strong>: This particular module is timely in that it aims to bring the concept of resilience to the career conversation. A resilient mindset is important as our students consider career options, their transition to the workplace and navigate the career planning and job search process. Research also supports the importance of career clarity on student engagement in the classroom, but career resilience isn't up to the students alone. It's also about creating a learning environment that fosters curiosity, flexibility, risk-taking, persistence and optimism. All essential career resilience factors.  </p>
                <p><strong>Becky</strong>: The career resilience section, like the other sections of the toolkit, is divided into four main parts. Learn, Discuss, Share and Design. By clicking on these different links. You'll learn about career resilience, the definitions and the models. You'll be able to discuss career resilience with your students using a variety of videos, podcasts and other online and print resources to promote classroom discussion.  </p>
                <p><strong>Tracey</strong>: There are a number of practical tip sheets and guides that you're invited to share with your students that will be helpful in their career development journey. There is even a quick questionnaire that you and your students may find insightful in assessing your career resilience. Becky: Lastly, the Design tab leads you to tips and resources to embed career resilience in your curriculum, teaching and learning activities. The hope is that no matter your discipline, you can find some inspiration that will allow you to engage your students in career conversations.</p>
                <p><strong>Becky</strong>: Lastly, the Design tab leads you to tips and resources to embed career resilience in your curriculum, teaching and learning activities. The hope is that no matter your discipline, you can find some inspiration that will allow you to engage your students in career conversations.  </p>
                <p><strong>Tracey</strong>: The Career Resilience Module is a compilation of materials from many career practitioners and researchers in the field and represents contributions from various postsecondary institutions. We hope that you will enjoy the learning journey yourself and find this to be a very useful and practical resource in the classroom.  </p>
            </accordion>
          </div>
          <p><a :href="`${publicPath}docs/career/Meet-Tracey-and-Becky.pdf`" target="_blank">Open transcript in a new tab</a></p>
        </div>
      </modal-popup>

      <!-- MAIN NAV -->
      <nav class="navbar main-nav">
        <div class="container">
          <router-link class="main-nav__item" to="/students-resilience/community">COMMUNITY</router-link>
          <router-link class="main-nav__item" to="/students-resilience/personal">PERSONAL</router-link>
          <router-link class="main-nav__item" to="/students-resilience/academic">ACADEMIC</router-link>
          <router-link class="main-nav__item" to="/students-resilience/career">CAREER</router-link>
        </div>
      </nav>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import ModalPopup from '@/components/ModalPopup.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation
//   // Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  components: {
    // SidebarMenu,
    Accordion,
    ModalPopup
    // Hooper,
    // Slide,
    // HooperNavigation
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      hooperSettings: {
        itemsToShow: 4,
        trimWhiteSpace: true,
        centerMode: false
      },

      openPopup: false
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
